import React from 'react';
export default function HeaderDigitalLucky() {
    return(
        <>
            <div className="w-full bg-emerald-950 flex flex-row h-14 items-center">
                <img className="w-8 h-8 ml-10" src="https://www.digitallucky.com.br/img/logo_digital_lucky_sem.png" alt="logo.png" />
                <div className="w-full max-w-full max-h-full flex flex-row justify-between items-center m-2">
                    <h1 className='text-white montserrat-400 m-5'>Início</h1>
                    <a className="w-fit h-fit px-5 py-1 m-4 text-white bg-orange-500 rounded-lg montserrat-500 hover:bg-orange-600 transition ease-in-out" href='/signup'>Entrar</a>
                </div>
            </div>
        </>
    )
}