import React from "react"

export default function FooterDigitalLucky() {
    return(
        <>
            <div className="w-full bg-emerald-950 flex flex-row h-14 items-center">
                <h1 className="text-white m-8">Digital Lucky © 2024</h1>
            </div>
        </>
    )
}