import FooterDigitalLucky from '../generalComponents/FooterDigitalLucky';
import SingIn from './components/SingIn';
export default function SignUp() {
  return (
    <>
      <div className="w-full h-full max-w-full max-h-full flex flex-col bg-orange-50">
        <div className="flex flex-col w-full h-full md:h-screen lg:h-screen max-w-full max-h-full justify-center items-center">
          <SingIn />
        </div>
        <FooterDigitalLucky />
      </div>
    </>
  );
}
