import HeaderDigitalLucky from '../generalComponents/HeaderDigitalLucky';
import FooterDigitalLucky from '../generalComponents/FooterDigitalLucky';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Home() {
  return (
    <>
      <div className="flex flex-col max-w-full max-h-full">
        <HeaderDigitalLucky />
        <div className="flex flex-col justify-center items-center bg-green-200 w-full max-w-full h-screen max-h-full">
          <h1 className="text-2xl md:text-4xl lg:text-4xl montserrat-300 m-2">
            CRIE SUAS AÇÕES COM A
          </h1>
          <h1 className="text-2xl md:text-4xl lg:text-4xl montserrat-900 m-2">
            <strong className="text-green-500">DIGITAL</strong>{' '}
            <strong className="text-orange-600">LUCKY</strong>{' '}
          </h1>
          <img
            className="w-20 h-22"
            src="https://www.digitallucky.com.br/img/logo_digital_lucky_sem.png"
            alt="logo.png"
          />
        </div>
        <div className="flex flex-col justify-center items-center bg-orange-50 w-full max-w-full h-screen max-h-full">
          <h1 className="text-2xl md:text-4xl lg:text-4xl montserrat-800 m-2">
            SUAS <strong className="text-green-500">AÇÕES</strong> DE FORMA
          </h1>
          <h1 className="text-2xl md:text-4xl lg:text-4xl montserrat-800 m-2">
            <strong className="text-orange-500">RÁPIDA</strong> E{' '}
            <strong className="text-orange-500">FÁCIL</strong>
          </h1>
        </div>
        <div className="flex flex-col w-full text-center max-w-full h-auto lg:h-screen">
          <div className="flex flex-col justify-center bg-green-400 items-center w-screen max-w-full h-auto lg:h-2/3 p-2">
            <div className="w-full md:w-2/3 lg:w-2/3 max-w-full flex flex-col lg:flex-row justify-center lg:justify-between">
              <div className="flex flex-col lg:flex-row justify-center items-center p-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4em"
                    height="4em"
                    viewBox="0 0 24 24"
                    className="lg:mr-2"
                  >
                    <path
                      fill="white"
                      d="M12 11.18c3.3-3 5-4.54 5-6.49C17 3.19 15.75 2 14.25 2c-.86 0-1.68.36-2.25 1c-.57-.64-1.39-1-2.31-1C8.19 2 7 3.25 7 4.75c0 1.89 1.7 3.43 5 6.43m-.82.82c-3-3.3-4.54-5-6.49-5C3.19 7 2 8.25 2 9.75c0 .86.36 1.68 1 2.25c-.64.57-1 1.39-1 2.31C2 15.81 3.25 17 4.75 17c1.89 0 3.43-1.7 6.43-5m1.65 0c2.99 3.3 4.53 5 6.48 5c1.5 0 2.69-1.25 2.69-2.75c0-.86-.36-1.68-1-2.25c.64-.57 1-1.39 1-2.31C22 8.19 20.75 7 19.25 7c-1.89 0-3.43 1.7-6.42 5m-.83.82c-3.3 3-5 4.54-5 6.49C7 20.81 8.25 22 9.75 22c.86 0 1.68-.36 2.25-1c.57.64 1.39 1 2.31 1c1.5 0 2.69-1.25 2.69-2.75c0-1.89-1.7-3.43-5-6.43"
                    />
                  </svg>
                </span>
                <div className="flex flex-col justify-start text-center lg:text-start text-white">
                  <h1 className="text-xl">Ações com até</h1>
                  <h1 className="text-xl">
                    <strong>10 MILHÕES</strong>
                  </h1>
                  <h1 className="text-xl">de cotas </h1>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row justify-center items-center p-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4em"
                    height="4em"
                    viewBox="0 0 24 24"
                    className="lg:mr-2"
                  >
                    <path
                      fill="white"
                      d="m16 11.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L5.46 19H22v2H2V3h2v14.54L9.5 8z"
                    />
                  </svg>
                </span>
                <div className="flex flex-col justify-start text-center lg:text-start text-white">
                  <h1 className="text-xl">Gráficos de</h1>
                  <h1 className="text-xl">
                    <strong>FATURAMENTO</strong>
                  </h1>
                </div>
              </div>
            </div>
            <div className="w-2/3 max-w-full flex flex-col lg:flex-row justify-center lg:justify-between mt-8">
              <div className="flex flex-col lg:flex-row justify-center items-center p-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4em"
                    height="4em"
                    viewBox="0 0 512 512"
                    className="lg:mr-2"
                  >
                    <path
                      fill="white"
                      d="M439 32v165h18V32zm-18 12.99L327.6 80l93.4 35zM165.9 103c-5 0-10.2 2.3-15.3 7c-6.2 5.8-11.5 15.1-13.8 26.3c-2.3 11.3-1 22 2.5 29.7c3.5 7.8 8.6 12.3 14.6 13.5c6 1.3 12.4-.9 18.7-6.6c6.1-5.8 11.5-15.1 13.8-26.4c2.2-11.3.9-22-2.5-29.7c-3.5-7.8-8.6-12.2-14.6-13.5c-1.1-.2-2.3-.3-3.4-.3m-38.4 78.5c-3.4 1.2-6.9 2.5-10.7 4.1c-24.85 15.7-42.2 31.2-59.84 55.7c-11.19 15.5-11.74 42-12.58 61.5l20.8 9.2c.87-27.8.36-39.3 13.27-55.3c9.83-12.2 19.33-25 37.55-28.9c1.6 28.9-2.6 73.7-14 119.6c20.5 2.8 37.6-.7 57-6.3c50.7-25.3 74.1-3.8 109.3 45.7l20.5-32.1c-24.6-28.9-48.5-75.1-117.2-57.3c5-27.3 5.6-45.4 8.6-72.6c.6-12 .8-23.9 1.1-35.7c-8.9 6.8-19.9 10.4-31 8.1c-9.5-2-17.3-7.9-22.8-15.7m144.2 7.3c-18.2 17.8-22.2 31-50.2 38.4l-22.5-24c-.4 12.8-.8 25.9-1.9 39.2c9.5 8.7 19.2 15.7 22.7 14.6c31.3-9.4 40.3-20.3 61.4-41.9zM409 215v96h-96v96h-96v78.1c102.3.2 167.8 1.1 270 1.8V215zM140.7 363.9c-13.6 2.5-27.8 3.3-43.44.9c-10.89 37.5-26.76 74.3-48.51 102.5l38.63 15.3c27.02-37.9 36.82-70.6 53.32-118.7"
                    />
                  </svg>
                </span>
                <div className="flex flex-col justify-start text-center lg:text-start text-white">
                  <h1 className="text-xl">Níveis de</h1>
                  <h1 className="text-xl">
                    <strong>CONTA</strong>
                  </h1>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row justify-center items-center p-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4em"
                    height="4em"
                    viewBox="0 0 24 24"
                    className="lg:mr-2"
                  >
                    <path
                      fill="white"
                      d="M9.06 1.93C7.17 1.92 5.33 3.74 6.17 6H3a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h9V8h2v3h9a1 1 0 0 0 1-1V8a2 2 0 0 0-2-2h-3.17C19 2.73 14.6.42 12.57 3.24L12 4l-.57-.78c-.63-.89-1.5-1.28-2.37-1.29M9 4c.89 0 1.34 1.08.71 1.71S8 5.89 8 5a1 1 0 0 1 1-1m6 0c.89 0 1.34 1.08.71 1.71S14 5.89 14 5a1 1 0 0 1 1-1M2 12v8a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-8h-9v8h-2v-8z"
                    />
                  </svg>
                </span>
                <div className="flex flex-col justify-start text-center lg:text-start text-white">
                  <h1 className="text-xl">Diversos tipos de</h1>
                  <h1 className="text-xl">
                    <strong>PRÊMIAÇÕES</strong>
                  </h1>
                </div>
              </div>
            </div>
            <div className="w-2/3 max-w-full flex flex-col lg:flex-row justify-center lg:justify-between mt-8">
              <div className="flex flex-col lg:flex-row justify-center items-center p-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4em"
                    height="4em"
                    viewBox="0 0 24 24"
                    className="lg:mr-2"
                  >
                    <path
                      fill="white"
                      d="M15.58 16.8L12 14.5l-3.58 2.3l1.08-4.12L6.21 10l4.25-.26L12 5.8l1.54 3.94l4.25.26l-3.29 2.68M20 12a2 2 0 0 1 2-2V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2a2 2 0 0 1-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 1-2-2"
                    />
                  </svg>
                </span>
                <div className="flex flex-col justify-start text-center lg:text-start text-white">
                  <h1 className="text-xl">Adicione</h1>
                  <h1 className="text-xl">
                    <strong>BILHETES</strong>
                  </h1>
                  <h1 className="text-xl">
                    <strong> </strong>
                  </h1>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row justify-center items-center lg:mr-14 p-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4em"
                    height="4em"
                    viewBox="0 0 24 24"
                    className="lg:mr-2"
                  >
                    <path
                      fill="white"
                      d="M3 11.991c0 5.638 4.239 8.375 6.899 9.536c.721.315 1.082.473 2.101.473V8l-9 3z"
                    />
                    <path
                      fill="white"
                      d="M14.101 21.527C16.761 20.365 21 17.63 21 11.991V11l-9-3v14c1.02 0 1.38-.158 2.101-.473M8.838 2.805L8.265 3c-3.007 1.03-4.51 1.545-4.887 2.082C3 5.62 3 7.22 3 10.417V11l9-3V2c-.811 0-1.595.268-3.162.805"
                      opacity="0.5"
                    />
                    <path
                      fill="white"
                      d="m15.735 3l-.573-.195C13.595 2.268 12.812 2 12 2v6l9 3v-.583c0-3.198 0-4.797-.378-5.335c-.377-.537-1.88-1.052-4.887-2.081"
                    />
                  </svg>
                </span>
                <div className="flex flex-col justify-start text-center lg:text-start text-white">
                  <h1 className="text-xl">Seus dados</h1>
                  <h1 className="text-xl">Sempre</h1>
                  <h1 className="text-xl">
                    <strong>SEGUROS</strong>
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between mx-10"></div>
            <div className="flex flex-row justify-between mx-10"></div>
          </div>
          <div className="flex flex-col justify-center text-center px-1 py-5 bg-orange-50 items-center w-full max-w-full h-1/3 max-h-full">
            <h1 className="text-2xl montserrat-800 m-1">
              QUANTO MAIS VOCÊ{' '}
              <strong className="text-orange-500">VENDE</strong>
            </h1>
            <h1 className="text-2xl montserrat-800 m-1">
              MENOS VOCÊ <strong className="text-green-500">PAGA</strong>
            </h1>
            <h1 className="text-xl mt-5">
              Evolua seu perfil vendendo cotas e{' '}
              <strong>aumente seu lucro</strong> .
            </h1>
          </div>
        </div>
        <div className="flex flex-col w-full max-w-full h-screen max-h-full">
          <div className="flex flex-col justify-center text-center bg-green-400 items-center w-full max-w-full h-1/2 max-h-full">
            <h1 className="text-white text-2xl md:text-3xl lg:text-3xl montserrat-600 mb-8">
              NÃO PAGE TAXA DE INSCRIÇÃO
            </h1>
            <p className="text-white justify-center w-2/3 md:w-1/2 lg:1/2">
              Cobramos apenas uma de taxa de no <strong>máximo 5%</strong>.
            </p>
          </div>
          <div className="flex flex-col bg-orange-50 text-center items-center w-full max-w-full h-1/2 max-h-full">
            <h1 className="montserrat-700 text-2xl md:text-3xl lg:text-3xl mt-8 mb-16">
              Como eu participo?
            </h1>
            <a
              className="bg-orange-500 text-white py-1 px-10 rounded-full text-xl md:text-3xl lg:text-3xl"
              href="/signup"
            >
              {' '}
              <strong>Clique aqui</strong> para se cadastrar
            </a>
            <p className="m-2">
              Após se cadastrar, em <strong>até 24 horas</strong> nossa equipe
              entrará em contato para <strong>mais informações</strong> .
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center bg-green-400 w-full max-w-full h-full max-h-full">
          <h1 className="text-2xl md:text-4xl lg:text-4xl montserrat-500 text-white mt-10 m-2">
            PERGUNTAS FREQUENTES
          </h1>
          <div className="max-w-full max-h-full h-full m-20 text-start justify-center">
            <Accordion
              sx={{
                backgroundColor: '#4ade80',
                boxShadow: '1',
              }}
            >
              <AccordionSummary
                className="text-xl"
                sx={{ color: 'black' }}
                id="panel-header"
                aria-controls="panel-content"
                expandIcon={<ExpandMoreIcon />}
              >
                Preciso pagar uma taxa inicial ?
              </AccordionSummary>
              <AccordionDetails className="text-base" sx={{ color: 'black' }}>
                <b>Não.</b>
                <br></br>
                Você não precisa pagar nenhuma taxa inicial para começar a
                utilizar nosso sistema ou para criar campanhas.
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: '#4ade80',
                boxShadow: '1',
              }}
            >
              <AccordionSummary
                className="text-xl"
                sx={{ color: 'black' }}
                id="panel-header"
                aria-controls="panel-content"
                expandIcon={<ExpandMoreIcon />}
              >
                Quais são as taxas para criar uma campanha ?
              </AccordionSummary>
              <AccordionDetails className="text-base" sx={{ color: 'black' }}>
                As taxas variam conforme o <b>nível do seu perfil</b>.
                <br />
                <br />
                <b>Nível 0:</b> Valor fixo.
                <br />
                <b>Nível 1:</b> 5%
                <br />
                <b>Nível 2:</b> 4%
                <br />
                <b>Nível 3:</b> 3%
                <br />
                <b>Nível 4:</b> 2.5%
                <br />
                <b>Nível 5:</b> 2%
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: '#4ade80',
                boxShadow: '1',
              }}
            >
              <AccordionSummary
                className="text-xl"
                sx={{ color: 'black' }}
                id="panel-header"
                aria-controls="panel-content"
                expandIcon={<ExpandMoreIcon />}
              >
                Posso criar campanhas de 10 milhões de cotas ?
              </AccordionSummary>
              <AccordionDetails className="text-base" sx={{ color: 'black' }}>
                Sim. Você pode criar campanhas de até 10 milhões de cotas{' '}
                <b>a partir do nível 1 do seu perfil</b>. Ou seja, a partir do
                momento em que você realizar suas vendas e conquistar o nível 1,
                você já pode criar campanhas de até 10 milhões de cotas.{' '}
                <p className="montserrat-500">
                  Caso já entre em algum nível avançado, essa funcionalidade já
                  estará disponível.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: '#4ade80',
                boxShadow: '1',
              }}
            >
              <AccordionSummary
                className="text-xl"
                sx={{ color: 'black' }}
                id="panel-header"
                aria-controls="panel-content"
                expandIcon={<ExpandMoreIcon />}
              >
                Como funciona o pagamento da taxa ?
              </AccordionSummary>
              <AccordionDetails className="text-base" sx={{ color: 'black' }}>
                Encontraremos a melhor forma de pagamento para cada cliente.
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: '#4ade80',
                boxShadow: '1',
              }}
            >
              <AccordionSummary
                className="text-xl"
                sx={{ color: 'black' }}
                id="panel-header"
                aria-controls="panel-content"
                expandIcon={<ExpandMoreIcon />}
              >
                Quais os benefícios de evoluir o nível do meu perfil ?
              </AccordionSummary>
              <AccordionDetails className="text-base" sx={{ color: 'black' }}>
                São <b>diversos</b> os benefícios ao evoluir seu perfil.
                <br />
                <br />
                <ul>
                  <i>
                    <li>- Redução de taxas</li>
                    <li>- Bots para whatsapp</li>
                    <li>- Maior limite de cotas por pedido</li>
                    <li>- E muito mais...</li>
                  </i>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: '#4ade80',
                boxShadow: '1',
              }}
            >
              <AccordionSummary
                className="text-xl"
                sx={{ color: 'black' }}
                id="panel-header"
                aria-controls="panel-content"
                expandIcon={<ExpandMoreIcon />}
              >
                Como evoluo o nível do meu perfil ?
              </AccordionSummary>
              <AccordionDetails className="text-base" sx={{ color: 'black' }}>
                O nível do seu perfil é evoluido conforme o faturamento do seu
                perfil.
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: '#4ade80',
                boxShadow: '1',
              }}
            >
              <AccordionSummary
                className="text-xl"
                sx={{ color: 'black' }}
                id="panel-header"
                aria-controls="panel-content"
                expandIcon={<ExpandMoreIcon />}
              >
                A Digital Lucky promove campanhas/sorteios ?
              </AccordionSummary>
              <AccordionDetails className="text-base" sx={{ color: 'black' }}>
                <b>Não.</b>
                <br />
                A DigitalLucky fornece apenas a infraestrutura necessária para a realização de campanhas/sorteios.
                <br />
                Em caso de investigação policial ou qualquer procedimento semelhante, a DigitalLucky está à disposição para fornecer qualquer tipo de dado ou informação relevante, conforme requerido pelas autoridades competentes.
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className="flex flex-col justify-center text-center items-center bg-green-200 w-full max-w-full h-screen max-h-full">
          <img
            className="w-40 h-42"
            src="https://www.digitallucky.com.br/img/logo_digital_lucky_sem.png"
            alt="logo.png"
          />
          <h1 className="m-12 lg:m-20 text-xl">
            A Digital Lucky teve seu início em 2024, com o objetivo de tornar
            justo e amplo a criação de sorteios. Não temos nenhuma ligação com
            quem realiza tal ato, apenas fornecemos a plataforma para o mesmo.
          </h1>
        </div>
        <FooterDigitalLucky />
      </div>
    </>
  );
}
